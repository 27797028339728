<template>
  <div>
    <el-button
      type="text"
      class="delete"
      icon="el-icon-circle-close"
      @click="deleSource"
    ></el-button>
    <div
      class="imgCon"
      v-if="!sourceMsg.url"
    >
      <el-button
        size="small"
        @click="selectImg"
        >从创意云选择视频</el-button
      >
      <el-upload
        class="upload-demo"
        action="#"
        :http-request="upload_img"
        :show-file-list="false"
        :before-upload="beforeUpload"
      >
        <el-button
          size="small"
          type="primary"
          @click="uploadType('video')"
          >点击上传视频</el-button
        >
        <div
          slot="tip"
          class="el-upload__tip"
          style="line-height: 28px"
        >
          <p>推荐长宽比：1:1</p>
          <p>推荐尺寸：1080 * 1080像素</p>
          <p>最小尺寸：600 * 600像素</p>
          <p>图中文本内容不超过20%效果更佳</p>
        </div>
      </el-upload>
    </div>

    <div
      class="imgCon"
      v-else
      v-loading="sourceMsg.videoStatus !== 'ready'"
    >
      <div class="tools">
        <el-button
          size="mini"
          class="uploadThumb"
          @click="uploadThumbShow = true"
          v-if="sourceMsg.videoStatus == 'ready'"
          >上传/选择封面</el-button
        >
        <i class="el-icon-zoom-in"></i>
        <i
          class="el-icon-delete"
          @click="deleImg"
        ></i>
        <el-upload
          class="upload-demo"
          action="#"
          :http-request="upload_img"
          :show-file-list="false"
          style="display: inline-block"
          :before-upload="beforeUpload"
        >
          <i
            class="el-icon-upload2"
            @click="uploadType('img')"
          ></i>
        </el-upload>
      </div>
      <div class="imgTips">
        <p>推荐长宽比：1:1</p>
        <p>推荐尺寸：1080 * 1080像素</p>
        <p>最小尺寸：600 * 600像素</p>
        <p>图中文本内容不超过20%效果更佳</p>
      </div>
      <video
        controls
        :src="sourceMsg.url"
        :poster="sourceMsg.thumbUrl"
        v-if="fileType == 'mp4'"
      ></video>
      <el-image
        :src="sourceMsg.url"
        :preview-src-list="[sourceMsg.url]"
        v-if="fileType == 'gif'"
      ></el-image>
    </div>
    <img-list
      :imgListShow="imgListShow"
      @close="close"
      @urlList="urlList"
      :uploadTypes="uploadTypes"
      :limit="1"
    ></img-list>
    <upload-video-thumb
      :uploadThumbShow.sync="uploadThumbShow"
      :sourceMsg="sourceMsg"
      @setThumb="setThumb"
    ></upload-video-thumb>
  </div>
</template>
<script>
import imgList from '../imgList';
import uploadVideoThumb from './uploadVideoThumb';
import adsMethods from '../../adsMethods.js';
import { ossClient } from '@/utils/alioss.js';
import BMF from 'browser-md5-file';
import { mediaUrlUploadFb, mediaUrlUploadCheck } from '@/api/creatAd.js';
export default {
  mixins: [adsMethods],
  components: {
    imgList,
    uploadVideoThumb,
  },
  props: ['uploadTypes', 'index', 'source', 'edit'],
  data() {
    return {
      sourceMsg: {
        url: '',
        source_id: null,
        thumbUrl: '',
        thumbList: [],
        cancleVideoUpload: false,
        type: 'video',
      },
      fileType: 'mp4',
      imgListShow: false,
      timer: null,
      uploadThumbShow: false,
      sourceType: 'video', // 上传类型
    };
  },
  methods: {
    //关闭创意云
    close(val) {
      this[val] = false;
    },
    // 从创意云选择图片
    selectImg() {
      this.imgListShow = true;
    },
    deleImg() {
      this.sourceMsg.url = '';
      this.timer = null;
      this.$emit('setSource', this.index, this.sourceMsg);
      this.$emit('emitCreative');
    },
    uploadType(val) {
      this.sourceType = val;
    },
    // 获取素材
    urlList(v) {
      let img = v[v.length - 1];
      console.log(img);
      this.sourceMsg.url = img.url;
      this.sourceMsg.source_id = img.id;
      this.sourceMsg.thumbUrl = img.thumbUrl;
      this.$emit('setSource', this.index, this.sourceMsg);
      this.$emit('emitCreative');
      this.uploadVideoFb(img.id);
    },
    // 上传类型
    upload_img(file) {
      // if (this.sourceType === 'video') {
      //   this.ossUpload(file.file)
      // } else {
      this.uploadImg(file).then((data) => {
        this.sourceMsg.url = data.url;
        this.sourceMsg.source_id = data.id;
        this.sourceMsg.thumbUrl = data.thumbUrl;
        this.$emit('setSource', this.index, this.sourceMsg);
        this.$emit('emitCreative');
        this.uploadVideoFb(data.id);
      });
      // }
    },
    setThumb(v, thumbList) {
      this.sourceMsg.thumbUrl = v;
      this.sourceMsg.thumbList = thumbList;
      this.$emit('setSource', this.index, this.sourceMsg);
      this.$emit('emitCreative');
    },
    deleSource() {
      this.timer = null;
      this.sourceMsg.cancleVideoUpload = true;
      this.$emit('deleSource', this.index);
    },
    ossUpload(data) {
      const that = this;
      const bmf = new BMF();
      bmf.md5(data, (err, md5) => {
        let checkData = {
          md5hash: md5,
        };
        mediaUrlUploadCheck(JSON.stringify(checkData)).then((res) => {
          if (res.data) {
            let data = res.data;
            let thumbnails = {
              isPreferred: true,
              uri: data.thumbUrl,
            };
            let thumbList = [thumbnails];
            that.sourceMsg.videoStatus = 'ready';
            that.sourceMsg.thumbList = thumbList;
            that.sourceMsg.url = data.url;
            that.sourceMsg.source_id = data.id;
            that.sourceMsg.thumbUrl = thumbList[0].uri;
            that.$emit('setSource', that.index, that.sourceMsg);
            that.$emit('emitCreative');
          } else {
            let filename = `ads/video/${md5}.MP4`;
            this.$showLoading();
            ossClient
              .put(filename, data)
              .then((result) => {
                if (result.res.status === 200) {
                  let url = `https://gamsad.giikin.cn/${filename}`;
                  let audioElement = new Audio(url);
                  audioElement.addEventListener('loadedmetadata', function (_event) {
                    let duration = audioElement.duration; //时长为秒，小数，182.36
                    let request = {
                      accountId: that.$store.getters.accountId, //广告账户id
                      md5hash: md5, //文件MD5 值
                      mediaSize: data.size, //文件大小
                      originFieldName: data.name, //原始文件名称
                      url: filename, //oss 链接地址
                      videoDuration: duration,
                    };
                    mediaUrlUploadFb(JSON.stringify(request)).then((res) => {
                      that.$hideLoading();
                      if (res.code === 0) {
                        let thumbnails = res.data.thumbnails;
                        let thumbList = [];
                        thumbnails.forEach((element, index) => {
                          let item = {
                            uri: element,
                          };
                          if (index === 0) item.isPreferred = true;
                          thumbList.push(item);
                        });
                        that.sourceMsg.videoStatus = 'ready';
                        that.sourceMsg.thumbList = thumbList;
                        that.sourceMsg.url = url;
                        that.sourceMsg.source_id = res.data.sourceId;
                        that.sourceMsg.thumbUrl = thumbList[0].uri;
                        that.$emit('setSource', that.index, that.sourceMsg);
                        that.$emit('emitCreative');
                      }
                    });
                  });
                }
              })
              .catch(() => {
                that.$hideLoading();
              });
          }
        });
      });
    },
  },
  watch: {
    source: {
      handler() {
        this.sourceMsg = {
          ...this.sourceMsg,
          ...this.source,
          videoStatus: this.edit ? this.sourceMsg.videoStatus || 'ready' : this.sourceMsg.videoStatus,
          thumbList:
            this.sourceMsg.thumbList && this.sourceMsg.thumbList.length
              ? this.sourceMsg.thumbList
              : [{ uri: this.sourceMsg.thumbUrl, isPreferred: true }],
        };
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
<style lang="scss">
.thumbList {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  .thumbWrap {
    width: 120px;
    height: 120px;
    margin: 8px;
    cursor: pointer;
    position: relative;
    i {
      position: absolute;
      top: -5px;
      right: -5px;
      background: #333;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      text-align: center;
      line-height: 16px;
      color: #fff;
      cursor: pointer;
    }
  }
  .el-image {
    width: 100%;
    height: 100%;
  }
}
.delete {
  position: absolute;
  top: -20px;
  right: -5px;
}
.uploadThumb {
  position: absolute;
  top: 100px;
  left: -100px;
  //     transform: translate(-50%,-50%);
}
</style>
